import React from "react"
import Layout from '../../components/layout'
import Section from '../../components/section'
import Banner from '../../components/banner'
import Container from '../../components/container'
import Typography from '../../components/typography'
import Seo from '../../components/seo';

export default function ElectricalSystemMaintenanceAndTesting(props) {
  return (
    <Layout {...props}>
      <Seo 
        title="Consultancy" 
        description="Performs appropriate inspection and testing throughout the whole electrical system including its equipment and conducts immediate action in order to settle properly whatever improper findings that might found on the establishment." 
      />
      <Section>
        <Banner 
          src="/img/sample-hero-image-1.jpg"
          header="Electrical System Maintenance and Testing"
        />
      </Section>
      <Section>
        <Container>
          <Typography component="p">
            Performs appropriate inspection and testing throughout the whole electrical system including its equipment
            and conducts immediate action in order to settle properly whatever improper findings that might found on
            the establishment.
          </Typography>
        </Container>
      </Section>
    </Layout>
  )
}
